// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-redirect-js": () => import("./../../../src/components/redirect.js" /* webpackChunkName: "component---src-components-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-babyschlaf-protokoll-app-js": () => import("./../../../src/pages/apps/babyschlaf-protokoll-app.js" /* webpackChunkName: "component---src-pages-apps-babyschlaf-protokoll-app-js" */),
  "component---src-pages-apps-babyschlaf-protokoll-app-partnerschaft-js": () => import("./../../../src/pages/apps/babyschlaf-protokoll-app-partnerschaft.js" /* webpackChunkName: "component---src-pages-apps-babyschlaf-protokoll-app-partnerschaft-js" */),
  "component---src-pages-apps-babyschlaf-protokoll-app-privacy-policy-js": () => import("./../../../src/pages/apps/babyschlaf-protokoll-app-privacy-policy.js" /* webpackChunkName: "component---src-pages-apps-babyschlaf-protokoll-app-privacy-policy-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-privacy-policy-wikiwars-js": () => import("./../../../src/pages/privacy-policy-wikiwars.js" /* webpackChunkName: "component---src-pages-privacy-policy-wikiwars-js" */),
  "component---src-pages-schnelle-webseiten-js": () => import("./../../../src/pages/schnelle-webseiten.js" /* webpackChunkName: "component---src-pages-schnelle-webseiten-js" */),
  "component---src-pages-seo-und-devops-js": () => import("./../../../src/pages/seo-und-devops.js" /* webpackChunkName: "component---src-pages-seo-und-devops-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-web-und-app-entwicklung-js": () => import("./../../../src/pages/web-und-app-entwicklung.js" /* webpackChunkName: "component---src-pages-web-und-app-entwicklung-js" */),
  "component---src-pages-webseiten-gestaltung-und-programmierung-js": () => import("./../../../src/pages/webseiten-gestaltung-und-programmierung.js" /* webpackChunkName: "component---src-pages-webseiten-gestaltung-und-programmierung-js" */)
}

